import React, { useState, useRef } from 'react';
import './App.css';
import { FaFileUpload, FaSpinner } from 'react-icons/fa';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [filename, setFilename] = useState('');
  const [model, setModel] = useState('gpt-4o'); // Default model
  const [taskDescription, setTaskDescription] = useState(''); // Task description text
  const [uploadedFiles, setUploadedFiles] = useState([]); // Track file paths after upload
  const fileInputRef = useRef(null);

  // Handle file uploads
  const handleChange = async (event) => {
    const files = event.target.files;
    if (files.length === 0) return;

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    try {
      setIsLoading(true);
      const response = await fetch('http://nbmakerapi.temp.swtest.ru/upload_file', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        alert('Files uploaded successfully');
        console.log(data);

        // Update uploadedFiles with file paths returned from the backend
        setUploadedFiles(data.files);
      } else {
        alert('Error uploading files');
      }
    } catch (error) {
      alert('Error: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle notebook generation
  const handleGenerate = async () => {
    setIsLoading(true);
    setFilename('');  
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 900000); // Timeout after 15 minutes

    const payload = {
      model,
      task: taskDescription,
      files: uploadedFiles  // Include file paths in the request
    };

    try {
      const response = await fetch('http://nbmakerapi.temp.swtest.ru/generate_notebook', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
        signal: controller.signal,
      });

      clearTimeout(timeoutId);
      if (response.ok) {
        const data = await response.json();
        setFilename(data.filename);
      } else {
        alert('Error generating notebook');
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        alert('Request timed out');
      } else {
        alert('Error: ' + error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Handle notebook download
  const handleDownload = () => {
    if (filename) {
      window.location.href = `http://nbmakerapi.temp.swtest.ru/download_notebook/${filename}`;
    }
  };

  return (
    <div className="app-container">
      <div className="content-box">
        <h1>Что будем создавать сегодня?</h1>
        <textarea
          className="input-field"
          placeholder="Опишите вашу задачу..."
          value={taskDescription}
          onChange={(e) => setTaskDescription(e.target.value)}
        ></textarea>
        <div>
          <label htmlFor="model-select">Модель:</label>
          <select
            id="model-select"
            value={model}
            onChange={(e) => setModel(e.target.value)}
          >
            <option value="gpt-4o">gpt-4o</option>
            <option value="o1-mini">o1-mini</option>
            <option value="gemini-pro">gemini-pro</option>
          </select>
        </div>
        <button
          onClick={filename ? handleDownload : handleGenerate}
          disabled={isLoading}
          className="generate-btn"
        >
          {isLoading ? (
            <FaSpinner className="spinner" />
          ) : filename ? (
            'Download'
          ) : (
            'Generate Notebook'
          )}
        </button>
      </div>

      <div className="drop-area">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleChange}
          style={{ display: 'none' }}
          multiple
        />
        <div
          className="file-drop-zone"
          onClick={() => fileInputRef.current.click()}
        >
          <FaFileUpload size={50} color="#888" />
          <p>Прикрепите файлы</p>
          <p className="file-support-text">Поддерживает: <span className="italic-text">.ipynb</span></p> {/* Only the extension is italicized */}
        </div>
      </div>
    </div>
  );
}

export default App;